<template>
  <div class="vue-downloads-wrapper container-fluid-max mb-5" v-if="categories">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-12 col-lg-8">
        <div class="row vue-downloads-categories">
          <div class="col-12 col-sm-12 col-lg-9 p-0">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 check p-0" v-for="category in categories" :key="category">
                <input type="checkbox" class="checkbox" :id="'checkbox-' + category.name" :name="category.name"
                       :value="category.name" v-model="category.checked">
                <label :for="'checkbox-' + category.name" class="subText"> {{ category.name }}</label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-3 p-0">
            <div class="dropdown w-100">
              <div class="arrow"></div>
              <select class="w-100" id="downloads-filter" @change="selectEvent()" v-model="sort">
                <option disabled selected value>{{sortTitles.downloadsSort}}</option>
                <option value="new">{{sortTitles.sortTitleNew}}</option>
                <option value="old">{{sortTitles.sortTitleOld}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row container-fluid-max">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="row mt-5" v-for="category in categories.filter(c => c.checked == true)" :key="category">
          <label :for="'table-' + category.name">
            <h4 class="mt-5">{{ category.name }}</h4>
          </label>
          <table class="vue-downloads-table mt-2" :id="'table-' + category.name">
            <tr v-for="file in category.files" :key="file">
              <td class="vue-downloads-td subText" width="70%">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="10" height="10" viewBox="0 0 10 10">
                  <g id="Symbol_156_58" data-name="Symbol 156 – 58" transform="translate(0 10) rotate(-90)">
                    <path id="Path_36" data-name="Path 36" d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z"
                          transform="translate(-2)"/>
                    <rect id="Rectangle_1467" data-name="Rectangle 1467" width="10" height="10" fill="none"/>
                  </g>
                </svg>
                {{ file.name }}
              </td>
              <td class="vue-downloads-td subText" width="10%">{{ file.fileSize }}</td>
              <td class="vue-downloads-th">
                <a :href="file.url" download class="animate link-primary">
                  <img :src="baseUrl + 'assets/images/download.svg'" width="16px" class="mr-3">Download
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadsComponent",
  props: ['categories', 'baseUrl', 'sortTitles'],
  data: function () {
    return {
      sort: ''
    }
  },
  methods: {
    selectEvent: function () {
      if (this.sort === 'new') {
        this.categories.forEach(category => {
          category.files.sort((a, b) => {
            const dateAasArray = a.date.split(/\D/);
            const dateBasArray = b.date.split(/\D/);
            const dateA = new Date(Date.UTC(dateAasArray[0], dateAasArray[1] - 1, dateAasArray[2], dateAasArray[3], dateAasArray[4], dateAasArray[5], dateAasArray[6]));
            const dateB = new Date(Date.UTC(dateBasArray[0], dateBasArray[1] - 1, dateBasArray[2], dateBasArray[3], dateBasArray[4], dateBasArray[5], dateBasArray[6]));
            return dateB - dateA;
          })
        })
      } else if (this.sort === 'old') {
        this.categories.forEach(category => {
          category.files.sort((a, b) => {
            const dateAasArray = a.date.split(/\D/);
            const dateBasArray = b.date.split(/\D/);
            const dateA = new Date(Date.UTC(dateAasArray[0], dateAasArray[1] - 1, dateAasArray[2], dateAasArray[3], dateAasArray[4], dateAasArray[5], dateAasArray[6]));
            const dateB = new Date(Date.UTC(dateBasArray[0], dateBasArray[1] - 1, dateBasArray[2], dateBasArray[3], dateBasArray[4], dateBasArray[5], dateBasArray[6]));
            return dateA - dateB;
          })
        })
      }
    }
  }
}
</script>

<style scoped>

#interne-downloads .dropdown {
  display:none !important;
}

.dropdown {
  position: relative;
}

.arrow {
  display: block;
  background-color: #fff;
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  border-right: 0.1em solid #003FCE;
  border-top: .1em solid #003FCE;
  transform: rotate(135deg);
  margin-right: .5em;
  margin-left: 1em;
  z-index: 99;
  right: 15px;
  top: 17px;
}

input:hover,
select:hover {
  border: 1px solid #112f61;
}

input:focus,
select:focus {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1) !important;
  border: 1px solid #112f61;
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
}

input,
select {
  height: 48px;
  border: 1px solid #003FCE;
  padding: 0 20px;
  color: #003fce;
  border-radius: 3px;
  background-color: transparent;
}

.vue-downloads-categories .check input.checkbox {
  height: 24px;
  border: 1px solid #003fce;
  width: 24px;
  float: left;
  padding: 0;
  border-radius: 3px;
  margin-right: 8px;
  -webkit-appearance: none !important;
}

.check label {
  font-size: 18px
}

td .subText {
  font-size: 18px
}

td a {
  text-transform: uppercase !important
}

.vue-downloads-td {
  text-align: left !important;
}

.vue-downloads-wrapper {
  padding-bottom: 50px;
}

.vue-downloads-categories {
  background-color: #f7f9ff;
  padding: 50px 30px;
}

.vue-downloads-table {
  border-collapse: collapse;
  width: 100%;
}

.vue-downloads-td, .vue-downloads-th {
  border-bottom: 1px solid #E2E2E2;
  text-align: right;
  height: 80px
}

@media (max-width: 991px) {
  .vue-downloads-wrapper {
    padding: 0px 0px 0px 0px;
  }

  .vue-downloads-categories {
    padding: 10px 0px 10px 0px;
  }
}
</style>