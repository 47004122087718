<template>
  <section class="container-fluid-max dzb-jobs-wrapper row justify-content-center align-items-center">
    <div class="dzb-jobs-wrapper-title col-12 col-lg-12 col-xl-10 m-5" v-if="dataTitle">
      <h1 class="title">
        {{ dataTitle.title }}
      </h1>
      <h5 class="subtitle mt-3">
        {{ dataTitle.subtitle }}
      </h5>
    </div>

    <template v-if="jobs.length > 0">
      <div v-for="job in jobs" class="dzb-job-wrapper col-12 col-lg-12 col-xl-10 mt-3 p-3 p-md-5" v-bind:key="job.Title">
        <h4 class="dzb-job-title mb-4">{{ job.Titel }}</h4>
        <div class="dzb-job-box dzb-job-ort">
          <p class="subText text-left">Standort</p>
          <p class="dzb-job-text">{{ job.arbeitsort }}</p>
        </div>
        <div class="dzb-job-box dzb-job-ort">
          <p class="subText text-left">Beschäftigung</p>
          <p class="dzb-job-text">{{ job.Stellenart }}</p>
        </div>
        <div class="dzb-job-clear"></div>
        <div>
          <a :href="job.jobOffer" target="_blank" class="animate link-primary">
            <img src="https://dzb-bank.de/assets/images/link-pfeil.svg" class="Link-Pfeil">
            Mehr erfahren
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="col-10 mt-3">
        <h5 v-if="dataTitle">
          {{dataTitle.noJobsText}}
        </h5>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "JobsComponent",
  props: ['dataTitle'],

  data: function () {
    return {
      jobs: [],
      firmenIdMain: '7',
      firmenIdDus: '8',
      jobsUrl: 'https://anwr.hr4you.org/api/jobs/json/set/Website'
    }
  },

  async mounted() {
    this.jobs = await this.getDzbJobs();
  },

  methods: {
    async getDzbJobs() {
      const response = await fetch(this.jobsUrl, {
        method: 'GET',
      });
      const jobs = await response.json();
      return jobs.HR4YOU_JOBS.filter(job => job.FirmenId === this.firmenIdMain || job.FirmenId === this.firmenIdDus);
    }
  }
}
</script>

<style scoped>
/* DZB jobs component */
.container-fluid-max.dzb-jobs-wrapper.row:last-child {
  margin-bottom: 100px;
}

.title, .subtitle {
  font-family: TheMix-Plain;
  font-stretch: normal;
  font-style: normal;
}

.title {
  font-weight: 600;
  color: #112f61;
}

.subtitle {
  text-align: left !important;
}

.dzb-jobs-wrapper {
  margin-top: -20px;
  background-color: white;
  padding: 35px 35px;
}

.dzb-jobs-wrapper-title {
}

.dzb-job-wrapper {
  background-color: #F7F9FF
}

.dzb-job-text {
  color: #112F61 !important;
  font-weight: 500 !important;
  margin-top: -10px;
}

.dzb-job-box {
  float: left;
  width: 45%
}

.dzb-job-clear {
  clear: left
}

@media (max-width: 1200px) {

}

@media (max-width: 991px) {
  h4 {
    font-size: 22px !important;
    line-height: 30px !important;
    letter-spacing: 0 !important;
  }

  .dzb-jobs-wrapper {
    padding: 15px;
    margin-top: -70px;
  }

  .subtitle {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #67696C !important;
  }
}

@media (max-width: 767px) {

}

@media (max-width: 576px) {

}
</style>