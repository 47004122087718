import Vue from 'vue'
import vueCustomElement from 'vue-custom-element'
import HeaderComponent from './components/header/HeaderComponent'
// import HeaderImageComponent from './components/pages/HeaderImageComponent'
// import PageTitleComponent from './components/pages/PageTitleComponent'
// import ImageTextBlockComponent from './components/pages/ImageTextBlockComponent'
// import IndustriesComponent from './components/pages/IndustriesComponent'
// import PaymentInfoComponent from './components/pages/PaymentInfoComponent'
// import InfosComponent from './components/pages/InfosComponent'
// import FooterComponent from './components/footer/FooterComponent'
import SideNavComponent from './components/side-nav/SideNavComponent'
import RecallFormComponent from './components/pages/forms/RecallFormComponent'
import EmailFormComponent from './components/pages/forms/EmailFormComponent'
// import ZentralregulierungMehrErfahrenComponent
//     from './components/pages/zentralregulierung/ZentralregulierungMehrErfahrenComponent'
import ContactsComponent from './components/pages/ContactsComponent'
// import InfoComponent from './components/pages/InfoComponent'
import BenefitsComponent from "./components/pages/BenefitsComponent";
import ImageInfoComponent from "./components/pages/ImageInfoComponent";
// import TitleAndImagesComponent from "./components/pages/TitleAndImagesComponent";
import ConditionsComponent from "./components/pages/ConditionsComponent";
import CreditCheckStepsComponent from "./components/pages/CreditCheckStepsComponent";
import CreditCheckBenefits from "./components/pages/CreditCheckBenefitsComponent";
import ForderantragStellenFormComponent from "./components/pages/forms/ForderantragStellenFormComponent";
import PortfolioComponent from "./components/pages/PortfolioComponent";
import VergleichsangebotFormularComponent from "./components/pages/forms/VergleichsangebotFormularComponent";
import BigImageComponent from "./components/pages/BigImageComponent";
import ManagementComponent from "./components/pages/ManagementComponent";
import DownloadsComponent from "./components/pages/DownloadsComponent";
import ErfahrungsberichteComponent from "./components/pages/ErfahrungsberichteComponent";
// import TextEditorComponent from "./components/pages/TextEditorComponent";
import TextAddressComponent from "./components/pages/TextAddressComponent";
import JobsComponent from "@/components/pages/JobsComponent";

import './main.css';

Vue.config.productionTip = false
Vue.use(vueCustomElement);

var ceil = Math.ceil;

Object.defineProperty(Array.prototype, 'chunk', {
    value: function (n) {
        return Array(ceil(this.length / n)).fill().map((_, i) => this.slice(i * n, i * n + n));
    }
});

Vue.customElement('sm-header-component', HeaderComponent);
// Vue.customElement('sm-header-image', HeaderImageComponent);
// Vue.customElement('sm-page-title', PageTitleComponent);
// Vue.customElement('sm-image-text-block', ImageTextBlockComponent);
// Vue.customElement('sm-payment-info', PaymentInfoComponent);
// Vue.customElement('sm-industries', IndustriesComponent);
// Vue.customElement('sm-infos', InfosComponent);
// Vue.customElement('sm-footer', FooterComponent);
Vue.customElement('sm-side-nav', SideNavComponent);
Vue.customElement('sm-recall-form', RecallFormComponent);
Vue.customElement('sm-email-form', EmailFormComponent);
// Vue.customElement('sm-zentralregulierung-mehr-erfahren', ZentralregulierungMehrErfahrenComponent);
Vue.customElement('sm-contacts', ContactsComponent);
// Vue.customElement('sm-info', InfoComponent);
Vue.customElement('sm-benefits', BenefitsComponent);
Vue.customElement('sm-image-info', ImageInfoComponent);
// Vue.customElement('sm-title-and-images', TitleAndImagesComponent);
Vue.customElement('sm-conditions', ConditionsComponent);
Vue.customElement('sm-credit-check-steps', CreditCheckStepsComponent);
Vue.customElement('sm-credit-check-benefits', CreditCheckBenefits);
Vue.customElement('sm-forderantrag-stellen-form', ForderantragStellenFormComponent);
Vue.customElement('sm-portfolio', PortfolioComponent);
Vue.customElement('sm-vergleichsangebot-formular', VergleichsangebotFormularComponent);
Vue.customElement('sm-big-image', BigImageComponent);
Vue.customElement('sm-management', ManagementComponent);
Vue.customElement('sm-downloads', DownloadsComponent);
Vue.customElement('sm-erfahrungsberichte', ErfahrungsberichteComponent);
// Vue.customElement('sm-text-editor', TextEditorComponent);
Vue.customElement('sm-text-addresses', TextAddressComponent);
Vue.customElement('sm-jobs-component', JobsComponent);
