<template>
    <div class="row mt-5 mb-5 container-fluid-max justify-content-center" v-if="textAddresses">
        <div class="col-md-6 col-lg-6">
            <div class="row">
                <div class="col-md-6 col-lg-6 p-0" v-for="item in textAddresses" :key="item"
                     v-bind:class="{'mt-5' : utility.isWindowMobileWidth()}">
                    <div class="row">
                        <div class="col-12" v-html="item.text"></div>
                        <div class="col-12 mt-3" v-for="add in item.addresses" :key="add">
                            <div class="row">
                                <div class="w-100">
                                    <img class="icon-wrapper mr-4" :src="add.icon">
                                    <div class="phone" v-html="add.text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {UtilityService} from "../../services/utility-service";

    export default {
        name: "TextAddressComponent",
        props: ['textAddresses'],
        data: function () {
            return {
                utility: new UtilityService()
            }
        }
    }
</script>

<style scoped>
    img {
        width: 28px;
        height: 28px;
        position: relative;
        float: left;
    }

    h4.phone {
        font-size: 18px
    }

    .icon-wrapper {
        padding: 0;
    }
</style>