<template>
    <div class="vue-image-info-wrapper container-fluid-max">
        <div class="row justify-content-center mt-5 mb-5" v-for="item in data" :key="item">

            <div class="col-12 col-xl-10 offset-0 offset-xl-1 mb-5">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <img class="vue-image-info-image w-100" :src="item.imageUrl">
                    </div>
                    <div class="col-12 col-md-7 pt-sm-2 pt-3 pl-sm-5 pl-3">
                        <div v-html="item.textHtml" class="itemText"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "ImageInfoComponent",
        props: ['data'],
        data: function () {
            return {
            }
        },
        computed: {

        },
        methods: {

        }
    }
</script>

<style scoped>
    .vue-image-info-wrapper {

    }

    .vue-image-info-image {
        width: 401px;
        height: 243px;
        object-fit: contain;
    }

    .itemText {
        font-family: TheMix-Plain;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #67696c;
    }

    @media (max-width: 991px) {
        .vue-image-info-image{
            width: 100%;
        }
    }
</style>