<template>
  <div class="vue-recall-arrange-wrapper" v-if="data">
    <div class="row justify-content-center container-fluid-max">
      <div class="col-12 text-center">
        <div class="row">
          <div class="col-12 text-center">
            <a
              style="cursor: pointer;"
              @click="navigateBack()"
              class="animate link-primary"
            >
              <img
                :src="baseUrl + 'assets/images/link-pfeil-links.svg'"
                @click="navigateBack()"
                class="vue-recall-arrange-back-btn"
                style="margin-right: 5px;"
              />
              {{ data.backButtonLable }}</a
            >
          </div>
        </div>
      </div>

      <div class="col-12">
        <PageTitleComponent :data="data.header" class="m-0">
        </PageTitleComponent>
        <svg
          class="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="32.039"
          height="32.039"
          viewBox="0 0 32.039 32.039"
        >
          <g id="Icon-Call" transform="translate(1.034 1.005)">
            <path
              id="Path_19"
              data-name="Path 19"
              d="M595.179,295.948a1.993,1.993,0,0,0-2.286-.129l-3.286,1.813a55.946,55.946,0,0,1-5.091-4.572h0l-.026-.024-.023-.026h0a55.906,55.906,0,0,1-4.572-5.09l1.813-3.286a1.993,1.993,0,0,0-.129-2.286l-3.621-5.434a1.46,1.46,0,0,0-1.429-.734c-1,.044-3.929,2.806-3.929,2.806-1.555,1.382-1.209,2.591-1.209,2.591,1.044,7.393,8.822,14.521,9.416,15.146.8.746,7.75,8.373,15.146,9.416,0,0,1.209.346,2.591-1.209,0,0,2.762-2.936,2.806-3.929a1.461,1.461,0,0,0-.734-1.429Z"
              transform="translate(-571.359 -276.171)"
              fill="none"
              stroke="#112f61"
              stroke-linecap="square"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>

      <div class="col-lg-3"></div>

      <div class="col-12 col-lg-5">
        <form method="post" action="" accept-charset="UTF-8" v-if="data.form">
          <input type="hidden" :name="csrfTokenName" :value="csrfToken" />
          <input type="hidden" name="action" value="contact-form/send" />
          <div v-html="redirectUrl"></div>

          <div class="row">
            <div class="col-12" v-if="data.form.concern">
              <input type="hidden" name="toEmail" v-bind:value="toEmail" />

              <div class="row">
                <label
                  class="subText text-left"
                  for="vue-recall-arrange-concern"
                >
                  {{ data.form.concern.label }}
                </label>
                <div class="dropdown w-100">
                  <div class="arrow"></div>
                  <select
                    v-model="data.form.concern.value"
                    class="form-control w-100"
                    id="vue-recall-arrange-concern"
                    name="message[Concern]"
                    @change="onConcernChange()"
                  >
                    <option disabled selected value>{{
                      data.form.concern.helpLabel
                    }}</option>
                    <option
                      :value="con"
                      v-for="con in data.form.concern.concerns"
                      :key="con"
                      >{{ con }}</option
                    >
                  </select>
                </div>
                <label class="subText text-left mt-2"
                  ><small>{{ data.form.concern.subText }}</small></label
                >
                <div
                  class="vue-recall-arrange-input-error mt-2 subText text-left"
                  v-if="this.data.form.concern.isError"
                >
                  {{ data.form.concern.errorMsg }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12" v-if="data.form.title">
              <div class="row">
                <label class="subText text-left" for="vue-recall-arrange-title">
                  {{ data.form.title.label }}
                </label>
                <div class="dropdown w-100">
                  <div class="arrow"></div>
                  <select
                    class="form-control col-12 w-100"
                    id="vue-recall-arrange-title"
                    name="message[Title]"
                    v-model="data.form.title.value"
                    @change="onTitleChange()"
                  >
                    <option disabled selected value>{{
                      data.form.title.helpLabel
                    }}</option>
                    <option
                      :value="tit"
                      v-for="tit in data.form.title.titles"
                      :key="tit"
                      >{{ tit }}</option
                    >
                  </select>
                </div>
                <div
                  class="vue-recall-arrange-input-error mt-2 subText text-left"
                  v-if="this.data.form.title.isError"
                >
                  {{ data.form.title.errorMsg }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-3" v-if="data.form.surname">
              <div class="row">
                <label
                  class="subText text-left"
                  for="vue-recall-arrange-surname"
                >
                  {{ data.form.surname.label }}
                </label>
                <input
                  id="vue-recall-arrange-surname"
                  type="text"
                  name="message[Surname]"
                  class="form-control w-100"
                  @input="onSurnameChange()"
                  v-model="data.form.surname.value"
                />
                <div
                  class="vue-recall-arrange-input-error mt-2 subText text-left"
                  v-if="data.form.surname.isError"
                >
                  {{ data.form.surname.errorMsg }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-3" v-if="data.form.name">
              <div class="row">
                <label class="subText text-left" for="vue-recall-arrange-name">
                  {{ data.form.name.label }}
                </label>
                <input
                  id="vue-recall-arrange-name"
                  type="text"
                  name="message[Name]"
                  class="form-control w-100"
                  @input="onNameChange()"
                  v-model="data.form.name.value"
                />
                <div
                  class="vue-recall-arrange-input-error mt-2 subText text-left"
                  v-if="data.form.name.isError"
                >
                  {{ data.form.name.errorMsg }}
                </div>
              </div>
            </div>
          </div>

          <input
            id="vue-recall-arrange-email"
            type="hidden"
            name="fromEmail"
            class="col-12 form-control mt-3 d-none"
            value="info@dzb-bank.de"
          />

          <div class="row ">
            <div class="col-12 mt-3" v-if="data.form.phone">
              <div class="row">
                <label class="subText text-left" for="vue-recall-arrange-phone">
                  {{ data.form.phone.label }}
                </label>
                <input
                  id="vue-recall-arrange-phone"
                  type="tel"
                  name="message[Phone]"
                  class="form-control w-100"
                  @input="onPhoneChange()"
                  v-model="data.form.phone.value"
                />
                <div
                  class="vue-recall-arrange-input-error mt-2 subText text-left"
                  v-if="data.form.phone.isError"
                >
                  {{ data.form.phone.errorMsg }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-5" v-if="data.form.privacy">
              <div class="row align-items-center">
                <input
                  id="vue-recall-arrange-privacy"
                  type="checkbox"
                  name="vue-recall-arrange-privacy"
                  class="form-control checkbox"
                  @input="onPrivacyChange()"
                  v-model="data.form.privacy.value"
                />
                <div
                  class="checkbox-text subText text-left"
                  for="vue-recall-arrange-privacy"
                  v-html="data.form.privacy.label"
                ></div>
                <div
                  class="vue-recall-arrange-input-error subText mt-2 text-left"
                  v-if="data.form.privacy.isError"
                >
                  {{ data.form.privacy.errorMsg }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label class="subText text-left" for="captcha"
                >Captcha - Bitte lösen Sie: {{ num1 }} + {{ num2 }} = ?</label
              >
              <input
                id="captcha"
                type="text"
                class="w-100 form-control"
                v-model="userCaptchaInput"
                @input="onCaptchaChange"
              />
              <div
                class="vue-recall-arrange-input-error mt-2 subText text-left"
                v-if="captchaError"
              >
                {{ captchaErrorMsg }}
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12" v-if="data.form.submitButton">
              <div class="row">
                <input
                  type="submit"
                  :value="data.form.submitButton.label"
                  class="form-control primary-btn"
                  @click="checkForm"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5" v-if="hasFormError">
            <div class="col-1">
              <svg
                version="1.1"
                id="Ebene_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 21.8 29"
                style="enable-background:new 0 0 21.8 29;"
                xml:space="preserve"
              >
                <g id="Gruppe_3395" transform="translate(-1111.405 -583)">
                  <g id="Gruppe_1754" transform="translate(266.775 -2443.232)">
                    <g id="Pfad_3416">
                      <path
                        class="st0"
                        style="fill:#EA0000;"
                        d="M855.6,3051.1c-6,0-10.9-4.9-10.9-10.9c0-6,4.9-10.9,10.9-10.9s10.9,4.9,10.9,10.9c0,4.7-3,8.9-7.5,10.4
                                                C857.9,3050.9,856.7,3051.1,855.6,3051.1z"
                      />
                    </g>
                  </g>
                  <g class="st1">
                    <path
                      class="st2"
                      style="fill:#FFFFFF;"
                      d="M1123.9,602.8c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                                            C1123.3,601.5,1123.9,602.1,1123.9,602.8z M1123.5,600.6h-2l-0.4-9.5h2.7L1123.5,600.6z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="col-11 vue-recall-arrange-input-error subText text-left mt-2"
            >
              {{ data.formErrorMsg }}
            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-3"></div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "../PageTitleComponent";
export default {
  name: "RecallFormComponent",
  components: { PageTitleComponent },
  props: [
    "data",
    "csrfTokenName",
    "csrfToken",
    "redirectUrl",
    "toEmail",
    "baseUrl",
  ],
  data: function() {
    return {
      hasFormError: false,
      num1: Math.floor(Math.random() * 10), // Random number for CAPTCHA
      num2: Math.floor(Math.random() * 10), // Random number for CAPTCHA
      userCaptchaInput: "", // User input for CAPTCHA
      captchaError: false, // Flag to indicate CAPTCHA error
      captchaErrorMsg: "Bitte lösen Sie das CAPTCHA.", // Error message
    };
  },
  methods: {
    navigateBack: () => window.history.back(),

    checkForm: function(e) {
      const correctCaptcha = this.num1 + this.num2;
      // CAPTCHA validation
      if (parseInt(this.userCaptchaInput) !== correctCaptcha) {
        this.captchaError = true;
        e.preventDefault();
        return false;
      }

      if (
        this.data.form.concern.value &&
        this.data.form.title.value &&
        this.data.form.name.value &&
        this.data.form.surname.value &&
        this.data.form.phone.value &&
        this.data.form.privacy.value
      ) {
        this.hasFormError = false;
        this.captchaError = false; // Reset CAPTCHA error
        return true;
      }

      if (!this.data.form.concern.value) {
        this.data.form.concern.isError = true;
      }

      if (!this.data.form.title.value) {
        this.data.form.title.isError = true;
      }

      if (!this.data.form.name.value) {
        this.data.form.name.isError = true;
      }

      if (!this.data.form.surname.value) {
        this.data.form.surname.isError = true;
      }

      if (!this.data.form.phone.value) {
        this.data.form.phone.isError = true;
      }

      if (!this.data.form.privacy.value) {
        this.data.form.privacy.isError = true;
      }

      this.hasFormError = true;
      e.preventDefault();
    },
    onConcernChange: function() {
      this.data.form.concern.concerns.forEach((c) => {
        if (c === this.data.form.concern.value) {
          this.data.form.concern.isError = false;
        }
      });
    },
    onTitleChange: function() {
      this.data.form.title.titles.forEach((t) => {
        if (t === this.data.form.title.value) {
          this.data.form.title.isError = false;
        }
      });
    },
    onNameChange: function() {
      if (this.data.form.name.value) {
        this.data.form.name.isError = false;
      } else {
        this.data.form.name.isError = true;
      }
    },
    onSurnameChange: function() {
      if (this.data.form.surname.value) {
        this.data.form.surname.isError = false;
      } else {
        this.data.form.surname.isError = true;
      }
    },
    onPhoneChange: function() {
      if (this.data.form.phone.value) {
        this.data.form.phone.isError = false;
      } else {
        this.data.form.phone.isError = true;
      }
    },
    onPrivacyChange: function() {
      if (!this.data.form.privacy.value) {
        this.data.form.privacy.isError = false;
      } else {
        this.data.form.privacy.isError = true;
      }
    },
    onCaptchaChange: function () {
      if (this.userCaptchaInput) {
        this.captchaError = false;
      }
    },
  },
};
</script>

<style scoped>
.vue-recall-arrange-wrapper {
  padding-top: 0;
  padding-bottom: 100px;
}

.primary-btn {
  width: 278px;
  height: 48px;
  border-radius: 2px;
  background-color: #003fce;
  font-family: TheMix-Plain;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.checkbox-text {
  width: 85%;
  float: left;
  padding-left: 20px;
  color: #112f61;
}

.vue-email-form-wrapper {
  padding-bottom: 100px;
}

label,
.label {
  color: #67696c;
  margin-bottom: 20px;
  margin-top: 15px;
}

input,
select,
textarea {
  height: 48px;
  border: 1px solid #003fce;
  padding: 0 20px;
  color: #003fce;
}

textarea {
  height: 250px;
}

input:hover,
select:hover,
textarea:hover {
  border: 1px solid #112f61;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #112f61;
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
}

.dropdown {
  position: relative;
}

input.checkbox {
  height: 24px;
  border: 1px solid #003fce;
  width: 24px;
  float: left;
  padding: 0;
  margin-top: -20px;
  border-radius: 3px;
  -webkit-appearance: none;
}

svg.icon {
  position: absolute;
  top: 60px;
  left: -420px;
  right: 0;
  margin: auto;
}

.arrow {
  display: block;
  background-color: #fff;
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  border-right: 0.1em solid #003fce;
  border-top: 0.1em solid #003fce;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1em;
  z-index: 99;
  right: 15px;
  top: 17px;
}

.vue-recall-arrange-input-error {
  color: #ea0000;
  font-size: 14px;
}
.vue-recall-arrange-back-btn:hover {
  cursor: pointer;
}

@media (max-width: 991px) {
  .vue-recall-arrange-wrapper {
    padding: 10px 0px 10px 0px;
  }
}
</style>
