<template>
    <div class="vue-benefits-wrapper">
        <div class="row container-fluid-max justify-content-center" v-for="benefit in benefits" :key="benefit">
            <div class="col-12">
                <div class="customHeading mb-5 mt-5">{{benefit.mainTitle}}</div>
            </div>
            <div class="col-12 col-xl-10">
                <div class="row" v-for="items in benefit.benefits.chunk(2)" :key="items">
                    <div class="col-12 col-lg-6 mt-5" v-for="item in items" :key="item">
                        <div class="row">
                            <div class="col-xl-2 p-0">
                                <img :src="item.image" class="vue-benefits-img mx-auto ml-lg-0 d-block">
                            </div>
                            <div class="col-xl-9 col-12 offset-0 offset-xl-1 mt-4 mt-xl-0 p-0">
                                <div class="row">
                                    <h4 class="icons-headline text-center text-lg-left w-100">{{item.title}}</h4>
                                    <div class="mt-2 subText text-center text-lg-left">
                                        {{item.benefitText}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {UtilityService} from "../../services/utility-service";

    export default {
        name: "BenefitsComponent",
        components: {},
        props: ['benefits'],
        data: function () {
            return {
                utility: new UtilityService()
            }
        },
        methods: {}
    }
</script>

<style scoped>
    .vue-benefits-wrapper {
        padding: 0px 0px 50px 0px;
    }

    .customHeading {
        font-family: TheMix-Plain;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: #001835;
    }

    .vue-benefits-img {
        width: 90px;
        height: 90px;
    }

    h4 {
        font-family: TheMix-Plain;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #001835;
    }

</style>