<template>
    <div class="vue-credit-check-benefits-wrapper container-fluid-max" v-if="data">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h3 class="underline-header mt-5">{{data.title}}</h3>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-sm-12 col-lg-10">
                <div class="row">
                    <div class="col-sm-12 col-md-6 mt-5" v-for="item in data.items" :key="item.image">
                        <div class="row">
                            <div class="col-sm-4"
                                 v-bind:class="{'text-right' : !utility.isWindowMobileWidth(), 'text-center': utility.isWindowMobileWidth()}">
                                <img :src="item.image">
                            </div>
                            <div class="col-sm-8 text-left subText" v-html="item.text"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-sm-4 text-center">
                <BtnComponent style="width: 100%;" :label="data.navigationLink.title" :primary="true"
                              @btnClicked="navigateToFinanz(data.navigationLink.url)"></BtnComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import {UtilityService} from "../../services/utility-service";
    import BtnComponent from "../lib/BtnComponent";

    export default {
        name: "CreditCheckBenefits",
        components: {BtnComponent},
        props: ['data'],
        data: function () {
            return {
                utility: new UtilityService()
            }
        },
        methods: {
            navigateToFinanz(url) {
                window.location.href = url;
            }
        }
    }
</script>

<style scoped>
    .vue-btn-primary {
        max-width: 300px;
    }
    
    .vue-credit-check-benefits-wrapper {
        padding-top: 0px;
        padding-bottom: 30px;
    }

    h3.underline-header:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 40px;
        padding-top: 17px;
        border-bottom: 4px solid #112f61;
        margin-bottom: 20px;
    }


    @media (max-width: 991px) {
        .vue-credit-check-benefits-wrapper {
            padding: 10px 10px 10px 10px;
        }
    }
</style>