<template>
    <div class="vue-management-wrapper">
        <div class="row container-fluid-max">
            <div class="col-lg-2"></div>
            <div class="col-12 col-md-12 col-lg-8">
                <div class="row text-left">
                    <div class="col-12 col-xs-12 col-md-6 mt-5 mb-5" v-for="manager in management" :key="manager">
                        <img :src="manager.image" class="vue-management-image">
                        <h3 class="mt-4">{{manager.name}}</h3>
                        <div class="subText w-75">{{manager.sector}}</div>
                        <br>
                        <hr class="w-100">
                        <div class="download">
                            <a :href="manager.link.url" download class="animate link-primary">
                                <img :src="baseUrl + 'assets/images/download.svg'" class="mr-4">
                                {{manager.link.text}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ManagementComponent",
        props: ['management', 'baseUrl']
    }
</script>

<style scoped>
    .vue-management-wrapper {
        padding: 0px 30px 0px 30px;
    }

    .vue-management-wrapper h3 {
        font-size: 1.5rem;
        color: #112F61
    }

    .vue-management-wrapper .subText {
        text-align: left;
        height: 40px
    }

    .vue-management-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    @media (max-width: 991px) {
        .vue-management-wrapper {
            padding: 10px 10px 10px 10px;
        }

        .vue-management-image {
            width: inherit;
            height: inherit;
            object-fit: contain;
        }
    }
</style>