<template>
    <button type="button" class="btn vue-btn" @click="clicked()"
            v-bind:class="{'vue-btn-primary': primary, 'vue-btn-secondary': secondary}">
        {{label}}
    </button>
</template>

<script>
    export default {
        name: "BtnComponent",
        props: {
            label: {
                type: String
            },
            primary: {
                type: Boolean,
                default: false
            },
            secondary: {
                type: Boolean,
                default: false
            }
        },
        data:  function() {
            return {

            }
        },
        methods: {
            clicked() {
                this.$emit('btnClicked');
            }
        },
    }
</script>

<style scoped>
    .vue-btn {
        width: 160px;
        height: 48px;
        border-radius: 2px;
    }

    .vue-btn-primary {
        background: #003fce;
        font-family: TheMix-Plain;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        background-color: #003fce;
    }

    .vue-btn-primary:hover {
        color: #ffffff;
    }

    .vue-btn-secondary {
        font-family: TheMix-Plain;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #003fce;
        text-transform: uppercase;
        border: solid 1px #003fce;
    }

    .vue-btn-secondary:hover {

    }
</style>