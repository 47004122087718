<template>
    <div class="container-fluid-max">
        <div class="row">
            <div class="vue-big-image-wrapper text-center mt-5 mb-5 col-12">
                <template v-if="windowHeight > 991">
                    <div v-if="language === 'en'">
                        <div v-for="image in imagesEn" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else-if="language === 'fr'">
                        <div v-for="image in imagesFr" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else-if="language === 'nl'">
                        <div v-for="image in imagesNl" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else-if="language === 'es'">
                        <div v-for="image in imagesEs" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else>
                        <div v-for="image in images" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="language === 'en'">
                        <div v-for="image in smallImagesEn" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else-if="language === 'fr'">
                        <div v-for="image in smallImagesFr" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else-if="language === 'nl'">
                        <div v-for="image in smallImagesNl" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else-if="language === 'es'">
                        <div v-for="image in smallImagesEs" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                    <div v-else>
                        <div v-for="image in smallImages" :key="image.url">
                            <img v-if="image.url" :src="image.url">
                        </div>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BigImage",
        props: ['images', 'imagesEn' , 'imagesFr', 'imagesEs', 'imagesNl', 'smallImages' , 'smallImagesEn' , 'smallImagesFr' , 'smallImagesEs' , 'smallImagesNl'],
        data() {
            return {
                windowHeight: window.innerWidth,
                language: ''
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.windowHeight = window.innerWidth;
            }
        },
        updated() {
            this.language = window.location.pathname.substring(1, 3);          
        }
    }
</script>

<style scoped>
    #wer-wir-sind-geschichte .vue-big-image-wrapper.col-12 {
        max-width: 68%;
        margin: auto;
    }

    @media (max-width: 991px) {
        .vue-big-image-wrapper img {
            width: 95%;
        }
    }
</style>