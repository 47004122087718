<template>
  <div class="row justify-content-center vue-header-navigation-mobile-wrapper text-left">

    <form class="col-12 input-group vue-header-navigation-mobile-dropdown vue-header-mobile-search"
          :action="entryUrl" id="mobile-form">

      <div class="input-group-prepend" ref="inputIcon">
                <span class="input-group-text vue-h-nav-mo-search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.28" height="23.28" viewBox="0 0 23.28 23.28">
                      <g id="Icon-Suche" transform="translate(0.75 0.75)">
                        <g id="Gruppe_1514" data-name="Gruppe 1514">
                          <circle id="Ellipse_2" data-name="Ellipse 2" cx="9.308" cy="9.308" r="9.308" fill="none"
                                  stroke="#003fce" stroke-miterlimit="10" stroke-width="1.5"/>
                          <line id="Linie_12" data-name="Linie 12" x2="6" y2="6" transform="translate(16 16)"
                                fill="none" stroke="#003fce" stroke-miterlimit="10" stroke-width="1.5"/>
                        </g>
                      </g>
                    </svg>
                </span>
      </div>

      <input id="link-search" name="searchWord" type="text" class="form-control" v-if="search"
             :placeholder="search.placeholder"
             ref="input" v-model="searchFieldValue" v-on:focus="onFocus()" v-on:focusout="onFocusLeave()">
    </form>

    <div class="col-12 vue-h-navigation-search-result" id="vue-h-navigation-search-result">
      <ul class="list-group list-group-flush mt-2 list-group-ul" id="vue-h-nav-mobile-sea-result-list"
          v-if="sResult">
        <p class="searchText bold" v-if="search.resultHeading">{{ search.resultHeading }}</p>
        <p class="mt-3 mb-0 searchText" style="font-size: 28px;" v-if="search.result">
          {{ sResult.searchLength }} {{ search.result }}
          "{{ lastSearchWord }}"
        </p>
        <li class="list-group-item" v-for="item in sResult.items" :key="item.title">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.561" height="11.061" viewBox="0 0 21.561 11.061">
            <g id="Link-Pfeil" transform="translate(0 0.53)">
              <g id="Gruppe_1515" data-name="Gruppe 1515" transform="translate(-276 -281.5)">
                <line id="Line_4" data-name="Line 4" x1="20" transform="translate(276 286.5)"
                      fill="none" stroke="#003fce" stroke-width="1.5"/>
                <path id="Pfad_2646" data-name="Pfad 2646" d="M291.5,281.5l5,5-5,5" fill="none"
                      stroke="#003fce" stroke-width="1.5"/>
              </g>
            </g>
          </svg>
          <a :href="item.url" @click="linkClicked()">{{ item.title }}</a>
        </li>
      </ul>
    </div>

    <template v-for="link in links">
      <template v-if="link.title !=='Search'">
        <div class="dropdown col-12 vue-header-navigation-mobile-dropdown" :key="link.title" v-if="link.visible">

          <button class="btn-block btn btn-secondary dropdown-toggle vue-header-navigation-mobile-dropdown-button"
                  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
            {{ link.title }}
          </button>

          <div class="dropdown-menu vue-header-navigation-mobile-dropdown-menu py-5"
               aria-labelledby="dropdownMenuButton">
            <div class="dropdown-item vue-header-navigation-mobile-dropdown-item"
                 v-for="entry in link.entries" :key="entry.image">
              <div class="row align-items-start" v-if="entry.visible">
                <img :src="entry.image" class="col-2 mobile-icon">
                <div class="col-10">
                  <div style=" position: relative; ">
                    <div class="navMobileEntryHeading">
                      {{ entry.heading }}
                    </div>
                    <div class="navMobileEntrySecondHeading" v-if="entry.secondHeading">
                      {{ entry.secondHeading }}
                    </div>
                    <div v-for="entryLink in entry.linkEntries" :key="entryLink.title">
                      <div class="navMobileEntryLink" v-if="entryLink.visible">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.914" height="11.414"
                             viewBox="0 0 21.914 11.414">
                          <g id="Link-Pfeil" transform="translate(0 0.707)">
                            <g id="Gruppe_1515" data-name="Gruppe 1515"
                               transform="translate(-276 -281.5)">
                              <line id="Line_4" data-name="Line 4" x1="20"
                                    transform="translate(276 286.5)" fill="none"
                                    stroke="#003FCE" stroke-width="2"/>
                              <path id="Pfad_2646" data-name="Pfad 2646"
                                    d="M291.5,281.5l5,5-5,5" fill="none" stroke="#003FCE"
                                    stroke-width="2"/>
                            </g>
                          </g>
                        </svg>
                        <a :href="entryLink.url" @click="linkClicked()"
                           class="navMobileEntryLinkTitle">
                          {{ entryLink.title }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-if="headerBtns">
      <div style="margin: 25px 30px 5px 30px; text-align: center; width: 100%;">
        <BtnComponent class="col-12" :label="headerBtns.creditCheck.text" :secondary="true"
                      @btnClicked="linkClicked(headerBtns.creditCheck.url)"
                      style="margin-bottom: 15px; width: 100%;"></BtnComponent>
        <BtnComponent class="col-12" style="width: 100%;" :label="headerBtns.dzbPortal.text" :primary="true"
                      @btnClicked="linkClicked(headerBtns.dzbPortal.url)"
                      v-bind:style="{ 'background-image': 'url('+ baseUrl+ '/assets/images/icon-schloss.svg)'}">
        </BtnComponent>
      </div>
    </template>

    <div class="col-12 pb-5 pt-2">
      <div class="row align-items-center text-center px-3">
        <div class="vue-header-navigation-mobile-email"
             v-for="(navItem, index) in navItems" v-bind:class="{'ml-2': index !==0}" :key="navItem.image">
          <div>
            <img class="vue-side-nav-sidenav-open-btn-img"
                 :src="navItem.image" @click="navigateTo(navItem.link.url)">
          </div>
        </div>
        <div class="col text-right">
          <LanguageSwitcherComponent :lang="lang" :base-url="baseUrl"></LanguageSwitcherComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-env jquery*/
import {UtilityService} from '../../services/utility-service'
import LanguageSwitcherComponent from './LanguageSwitcherComponent'
import BtnComponent from "../lib/BtnComponent";

export default {
  name: "HeaderNavigationMobileComponent",
  components: {BtnComponent, LanguageSwitcherComponent},
  props: ['links', 'navItems', 'entryUrl', 'lang', 'sResult', 'baseUrl', 'headerBtns', 'search'],
  data: function () {
    return {
      activeLink: null,
      searchFieldValue: '',
      searchResultList: [],
      utility: new UtilityService(),
      lastSearchWord: ''
    }
  },
  computed: {},
  methods: {
    searchResult() {
      if (this.searchFieldValue.length > 0) {
        this.utility.addSearchToLocalstorage(this.searchFieldValue, true);
      } else {
        event.stopPropagation();
      }
    },
    linkClicked: function (url) {
      this.utility.removeSearchFromLocalstorage();
      window.location.href = url;
    },
    navigateTo: function (url) {
      this.linkClicked();
      window.location.href = url;
    },
    onFocus() {
      const navbar = document.getElementById('navbar-expand');
      if (navbar) {
        navbar.classList.add('search-focus');
      }
    },
    onFocusLeave() {
      const navbar = document.getElementById('navbar-expand');
      const searchResultEl = document.getElementById('vue-h-navigation-search-result');
      if (navbar) {
        navbar.classList.remove('search-focus');
        this.searchFieldValue = '';
      }
      if (searchResultEl) {
        searchResultEl.classList.add('searchResultHide');
      }
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('searchWord');

    if (!myParam) {
      this.utility.removeSearchFromLocalstorage();
    } else if (this.utility.getSearchFromLocalStorage()) {
      document.getElementById("contentDisabledElement").classList.toggle("vue-side-nav-content-disabled");
      this.searchFieldValue = this.utility.getSearchFromLocalStorage();
      this.lastSearchWord = this.utility.getSearchFromLocalStorage();
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      $('#navbarSupportedContent').collapse({
        toggle: true
      })
      this.onFocus();

    }

    $("#navbarSupportedContent").on("hide.bs.collapse", function () {
      UtilityService.removeDisableContentBody();
    });

    $("#navbarSupportedContent").on("show.bs.collapse", function () {
      UtilityService.addDisableContentBody();
    });
  },
  watch: {
    searchFieldValue: function () {
      this.searchResult();
    }
  }
}
</script>

<style>

.vue-header-navigation-mobile-dropdown {
  padding: 0;
  margin: 0;
}

.vue-header-navigation-mobile-dropdown button {
  border-radius: 0;
}

.vue-header-navigation-mobile-dropdown-menu {
  position: static;
  width: 100%;
}

.vue-header-navigation-mobile-dropdown-button {
  border: solid 1px #e4ebff;
  background-color: #f7f9ff;
  font-family: TheMix-Plain;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: left;
  color: #003fce;
}

.vue-header-navigation-mobile-dropdown-button {
}

.vue-header-navigation-mobile-dropdown-item:first-child {
  margin-top: 0;
}

.vue-header-navigation-mobile-dropdown-item {
  white-space: unset;
  margin-top: 50px;
}

.vue-header-navigation-mobile-email div {
  width: 60px;
  height: 60px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: relative;
}

.vue-header-navigation-mobile-email div img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto
}

.vue-header-mobile-search {
}

.vue-h-navigation-search-result {
  display: none;
}

#vue-h-nav-mobile-sea-result-list {
  position: relative;
}

#vue-h-nav-mobile-search-btn:disabled {
  background-color: #00000012;
}

.vue-h-nav-mo-search-icon {
  background-color: unset;
}

.vue-side-nav-content-disabled {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: rgba(17, 47, 97, 0.75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navMobileEntryHeading {
  font-family: TheMix-Plain;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #001835;
}

.navMobileEntryLink {
  margin-top: 15px;
}

.navMobileEntrySecondHeading {
  font-family: TheMix-Plain;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #67696C;
  margin-top: 15px;
}

.navMobileEntryLinkTitle {
  font-family: TheMix-Plain;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #003fce;
  position: relative;
  top: 3px;
  padding-left: 5px;
}

@media (max-width: 991px) {
  .vue-side-nav-sidenav-close-btn {
    font-size: 46px;
  }

  a:hover {
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  .input-group {
    height: 95px;
    border: none;
  }

  .input-group > .form-control {
    height: 95px;
    border: none;
  }

  .input-group-text {
    border: none;
    margin-left: 20px;
  }

  .form-control:focus {
    box-shadow: none;
  }

  .form-control {
    border-radius: unset;
  }

  .vue-header-navigation-mobile-dropdown-button {
    background-color: #f7f9ff;
  }

  .vue-header-navigation-mobile-dropdown button.dropdown-toggle:after {
    display: inline-block;
    position: absolute;
    right: 35px;
    top: 28px;
    padding: 3px;
    border: solid #003fce;
    border-width: 0 2px 2px 0;
    content: "";
    transform: rotate(45deg);
  }

  .vue-header-navigation-mobile-dropdown button {
    border: solid 1px;
    border-radius: 0;
    height: 67px;
    background-color: #F7F9FF;
    border-color: #E4EBFF;
    color: #003fce;
    font-size: 20px;
    padding-left: 30px;
  }

  .show > .btn-secondary.dropdown-toggle {
    background-color: #1f3e86;
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #1f3e86;
    border-color: #1f3e86;
  }

  .btn-secondary:focus {
    background-color: #1f3e86;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle,
  .btn-secondary.dropdown-toggle:visited {
    background-color: #1f3e86 !important;
    border-color: none;
  }

  .vue-header-navigation-mobile-dropdown.show button.dropdown-toggle:after,
  .btn-secondary:not(:disabled):not(.disabled):active:after,
  .btn-secondary:hover:after {
    border-color: white !important;
  }

  .btn:focus {
    box-shadow: unset;
  }

  .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    top: -60px;
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    box-shadow: none;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
  }
}
</style>