<template>
    <div class="vue-page-title-wrapper col-12 container-fluid-max" v-if="Array.isArray(data)">
        <div class="row justify-content-center" v-if="data">
            <div v-for="header in data" :key="header"
                 v-bind:class="{'title-description' : header.subTitle !== null,
                 'col-12' : header.subTitle === null, 'col-md-10 col-12' : header.subTitle !== null}">
                <div class="row text-left text-lg-center justify-content-lg-center justify-content-left p-0">
                    <div class="col-xl-2 d-none d-xl-flex"></div>
                    <div class="col-12 col-xl-8 p-0 p-md-auto">
                        <h3 class="underline-header">{{header.mainTitle}}</h3>
                    </div>
                    <div class="col-xl-2 d-none d-xl-flex"></div>
                </div>

                <div class="row text-left text-lg-center justify-content-lg-center justify-content-left mt-2 p-0"
                     v-if="header.subTitle">
                    <div class="col-12 col-xl-6 p-md-auto subtitle">{{header.subTitle}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="vue-page-title-wrapper col-12 container-fluid-max p-0 p-md-auto" v-else>
        <div class="row justify-content-center" v-if="data">
            <div class="p-0 p-md-auto" v-bind:class="{'title-description' : data.subTitle !== null,
            'col-12' : data.subTitle === null, 'col-md-10 col-12' : data.subTitle !== null}">
                <div class="row text-center justify-content-center p-0 p-md-auto">
                    <div class="col-xl-2 d-none d-xl-flex main-title-big-col2"></div>
                    <div class="col-12 col-md-10 col-xl-8 p-0 p-md-auto main-title-big-col8">
                        <h3 class="underline-header">{{data.mainTitle}}</h3>
                    </div>
                    <div class="col-xl-2 d-none d-xl-flex main-title-big-col2"></div>
                </div>

                <div class="row text-center justify-content-center mt-2" v-if="data.subTitle">
                    <div class="col-12 col-xl-8 subtitle main-title-big-col8">{{data.subTitle}}</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "PageTitleComponent",
        props: ['data'],
        data: function () {
            return {}
        },
        computed: {},
        methods: {}
    }
</script>

<style scoped>
    .vue-page-title-wrapper {
        margin-top: 80px;
        margin-bottom: 50px;
    }

    .subtitle {
        font-family: TheMix-Plain;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: center;
        color: #67696c;
    }

    .underline-header {
        font-family: TheMix-Plain;
        font-size: 40px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #112f61;
    }

    .underline-header:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 80px;
        padding-top: 17px;
        border-bottom: 4px solid #112f61;
    }

    @media (max-width: 991px) {
        .underline-header {
            font-size: 30px;
        }

        .vue-page-title-wrapper {
            margin-top: 50px !important;
            margin-bottom: 50px !important;
        }
    }
</style>