<template>
    <div class="vue-header animate sticky-top" id="vue-header">
        <div class="row vue-header-wrapper container-fluid-max">
            <div class="col-12 vue-header-content-wrapper">
                <nav class="navbar navbar-expand-lg navbar-light" id="navbar-expand">

                    <img class="navbar-brand animate" @click="navigateToHome()" :src="getLogoImage()">
                    <div id="mobile-menu" class="hamburger-menu-wrapper collapsed animate"
                         data-toggle="collapse"
                         data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                         aria-expanded="false" aria-label="Toggle navigation" v-on:click="mobileMenu()">
                        <div class="hamburger-menu">
                        </div>
                    </div>

                    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <template v-if="displayWeb">
                            <HeaderNavigationWebComponent :links="links" @linkClicked="linkClicked($event)" :lang="lang"
                                                          :headerBtns="headerBtns" :baseUrl="baseUrl">
                            </HeaderNavigationWebComponent>
                        </template>

                        <template v-else>
                            <HeaderNavigationMobileComponent :links="links" @linkClicked="linkClicked($event)"
                                                             :nav-items="navItems" :lang="lang" :s-result="sResult"
                                                             :base-url="baseUrl" :headerBtns="headerBtns"
                                                             :search="search">
                            </HeaderNavigationMobileComponent>
                        </template>
                    </div>
                </nav>
            </div>
            <div class="col-12">
                <div class="row justify-content-end">
                    <div class="col-12" v-bind:class="{'vue-header-border-bottom': clLink.link !== null }"></div>
                </div>
            </div>
        </div>

        <template v-if="displayWeb">
            <HeaderMenuWebComponent :link="clLink.link" v-if="clLink.link" :s-result="sResult" :base-url="baseUrl"
                                    :search="search">
            </HeaderMenuWebComponent>
        </template>
    </div>
</template>

<script>
    import HeaderNavigationWebComponent from './HeaderNavigationWebComponent'
    import HeaderMenuWebComponent from './HeaderMenuWebComponent'
    import HeaderNavigationMobileComponent from "./HeaderNavigationMobileComponent";
    import {UtilityService} from "../../services/utility-service";

    export default {
        name: 'SmHeaderComponent',
        components: {
            HeaderNavigationMobileComponent,
            HeaderNavigationWebComponent,
            HeaderMenuWebComponent
        },
        props: ['title', 'links', 'logo', 'homeUrl', 'navItems', 'sResult', 'lang', 'entryUrl', 'baseUrl', 'headerBtns',
            'logoMobile', 'search'],
        data: function () {
            return {
                displayWeb: true,
                clLink: {
                    link: null,
                    clicked: false
                },
                utility: new UtilityService()
            }
        },
        computed: {},
        methods: {
            getWidth() {
                if (window.innerWidth > 991) {
                    this.displayWeb = true
                } else {
                    this.displayWeb = false
                }
            },
            linkClicked(link) {
                if (this.clLink.link == link) {
                    this.clLink.link = null;
                    UtilityService.removeDisableContentBody();
                    return;
                }

                this.clLink.link = link
                this.clLink.clicked = !this.clLink.clicked
                UtilityService.addDisableContentBody();
            },
            navigateToHome() {
                this.utility.removeSearchFromLocalstorage();
                window.location.href = this.homeUrl;
            },
            getLogoImage: function () {
                if (this.displayWeb) {
                    return this.logo;
                } else {
                    return this.logoMobile;
                }
            },
            headerNavigationBlockSetPadding(paddingPx) {
                let headerBlock = document.getElementById("header-navigation-block");
                if (headerBlock) {
                    headerBlock.style = "padding-top: " + paddingPx + ";"
                }
            },
            headerSetPadding() {
                let header = document.getElementById("vue-header");
                if (header) {
                    header.classList.add("scrolled");
                }
            },
            removeScrolled() {
                let header = document.getElementById("vue-header");
                if (header) {
                    header.classList.remove("scrolled");
                }
            },
            onScroll() {
                if (window.pageYOffset > 0) {
                    this.headerSetPadding();
                } else {
                    this.removeScrolled();
                }
            },
            mobileMenu() {
                const mobileMenuEl = document.getElementById('mobile-menu');
                if (mobileMenuEl) {
                    const isAriaExpanded = mobileMenuEl.getAttribute('aria-expanded');
                    const searchResultElement = document.getElementById('vue-h-navigation-search-result');
                    const mobileFormEl = document.getElementById('mobile-form');
                    const navBarExpEl = document.getElementById('navbar-expand');
                    const bodyEl = document.getElementsByTagName('body');

                    if (isAriaExpanded === 'false') {
                        searchResultElement.classList.add('searchResultHide');
                        navBarExpEl.classList.remove('search-focus');
                        mobileFormEl.reset();
                        bodyEl[0].style.overflow = 'hidden';
                    } else  {
                        bodyEl[0].style.overflow = 'auto';
                        navBarExpEl.classList.remove('search-focus');
                    }
                }
            }
        },
        created() {
            this.getWidth();
            window.addEventListener('resize', this.getWidth);
            window.addEventListener('scroll', this.onScroll);
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.getWidth);
            window.removeEventListener('scroll', this.onScroll);
        },

        mounted() {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .vue-header.scrolled {
        padding: 10px 0 0 0;
        -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    }

    .vue-header {
        padding: 30px 0px 20px 0px;
        background-color: #ffffff;
        border-bottom: 1px solid #003fce;
    }

    .col-12 {
    }

    .vue-side-nav-content-disabled {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background: rgba(17, 47, 97, 0.75);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar-brand {
        cursor: pointer;
        padding: 0;
        margin: 0;
        max-width: 200px;
        position: relative;
        top: -3px;
        webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    .navbar-light .navbar-toggler-icon {
        float: left;
        background-image: unset;
        width: 100%;
        height: 3px;
        margin-bottom: 5px;
        background-color: #003fce;
    }

    .navbar {
        padding: 0 15px;
    }

    .navbar-light .navbar-toggler {
        width: 47px;
        height: 34px;
        border: none;
    }

    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
        outline: none;
    }

</style>
