<template>
    <div class="vue-contacts" v-if="data">
        <div class="row justify-content-center container-fluid-max next-w100">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center p-0 mt-0" v-if="data.mainTitle || data.secondTitle">
                <PageTitleComponent :data='{mainTitle: data.mainTitle, subTitle: data.secondTitle}'>
                </PageTitleComponent>
            </div>

            <div class="col-12 offset-lg-0 col-lg-12 col-xl-10 offset-xl-2 text-center"
                 v-if="data.contacts.length == 1">
                <div v-for="contact in data.contacts" :key="contact.imageUrl" class="w-100">
                    <template v-if="!contact.isSingle">
                        <div class="row align-items-center">
                            <div class="col-md-12 col-lg-2">
                                <img class="vue-contacts-img w-100" :src="contact.imageUrl">
                            </div>
                            <div class="col-md-12 col-lg-4 contactName">
                                <h4>
                                    {{contact.name}}
                                </h4>
                                <p class="contactPosition mb-0">
                                    {{contact.position}}
                                </p>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <h3 class="contactTitle text-lg-left text-center">
                                    {{contact.title}}
                                </h3>
                                <p class="mt-3 text text-lg-left text-center">
                                    {{contact.text}}
                                </p>
                                <div class="contact-button animate w-50 mt-4 mx-auto ml-lg-0"
                                     v-on:click="navigateTo(contact.navigationTo.link)"
                                     v-on:mouseover="changeImg('assets/images/icon-call-white.svg')"
                                     v-on:mouseleave="changeImg('assets/images/icon-call.svg')">
                                    <img :src="baseUrl + 'assets/images/icon-call.svg'"
                                         style="margin-right: 10px; margin-top:-5px" ref="btnImage">
                                    <a :href="contact.navigationTo.link">{{contact.navigationTo.text}}</a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row contact-two-persons justify-content-center">
                            <div class="col-12 col-xl-12">
                                <div class="row mt-3 mb-3">
                                    <div class="col-12 col-xl-12 contact-mobile px-0" v-for="(contact) in data.contacts"
                                         :key="contact.imageUrl">
                                        <div class="row justify-content-center align-items-center">
                                            <div class="col-12 col-lg-2 image text-right">
                                                <img :src="contact.imageUrl" class="vue-contacts-img">
                                            </div>
                                            <div class="col-12 col-lg-6 pl-0 pl-sm-5 contactName text-center">
                                                <div class="row">
                                                    <div class="col-12 name">
                                                        {{contact.name}}
                                                    </div>
                                                    <div class="col-12 tel">
                                                        <img :src="baseUrl+'/images/icon-call-blau.svg'">
                                                        <a :href="'tel:' + contact.phone" class="animate">{{contact.phoneVisual}}</a>
                                                    </div>
                                                    <div class="col-12 mail" v-if="contact.email">
                                                        <img :src="baseUrl+ '/images/icon-mail-blau.svg'">
                                                        <a :href="'mailto:' + contact.email" class="animate">{{contact.email}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else>
                <div class="row contact-two-persons justify-content-center">
                    <div class="col-12 col-xl-10">
                        <div class="row mt-5">
                            <div class="col-12 col-xl-6 contact-mobile px-0" v-for="(contact) in data.contacts"
                                 :key="contact.imageUrl">
                                <div class="row align-items-center">
                                    <div class="col-12 col-lg-4 image">
                                        <img :src="contact.imageUrl" class="vue-contacts-img w-100">
                                    </div>
                                    <div class="col-12 col-lg-8 contactName text-center">
                                        <div class="row">
                                            <div class="col-12 name">
                                                {{contact.name}}
                                            </div>
                                            <div class="col-12 tel">
                                                <img :src="baseUrl+'/images/icon-call-blau.svg'">
                                                <a :href="'tel:' + contact.phone" class="animate">{{contact.phoneVisual}}</a>
                                            </div>
                                            <div class="col-12 mail">
                                                <img :src="baseUrl+ '/images/icon-mail-blau.svg'">
                                                <a :href="'mailto:' + contact.email" class="animate">{{contact.email}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageTitleComponent from "./PageTitleComponent";
    import {UtilityService} from "../../services/utility-service";

    export default {
        name: "ContactsComponent",
        components: {PageTitleComponent},
        props: ['data', 'baseUrl'],
        data: function () {
            return {
                utility: new UtilityService()
            }
        },
        methods: {
            navigateTo(href) {
                window.location.href = href;
            },
            changeImg(href) {
                this.$refs.btnImage[0].src = this.baseUrl + href;
            }
        }
    }
</script>

<style scoped>
    .vue-contacts .next-w100>div {
        width: 100% !important
    }

    .vue-contacts .vue-page-title-wrapper {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .contact-two-persons div {
        text-align: left;
    }

    .contact-two-persons .name {
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: 24px;
    }

    .contact-two-persons .tel a,
    .contact-two-persons .mail a{
        font-size: 16px;
        color: #112F61;
        text-decoration: none;
    }

    .contact-two-persons .tel a:hover,
    .contact-two-persons .mail a:hover{
        color: #003FCE;
    }

    .contact-two-persons .tel img,
    .contact-two-persons .mail img {
        position:relative;
        width: 34px;
        height: 20px;
        padding-right:15px
    }

    .vue-contacts {
        margin: 50px auto 50px auto;
        padding: 50px 50px 50px 50px;
        background-color: #fffcf7;
        max-width: 1458px
    }

    p.text {
        color: #67696C;
    }

    .contactName {
        font-family: TheMix-Plain;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #112f61;
    }

    .contactPosition {
        font-family: TheMix-Plain;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
        color: #112f61;
    }


    .contactTitle {
        font-family: TheMix-Plain;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #001835;
    }

    .vue-contacts-img {
        max-width: 180px;
        height: auto;
        object-fit: contain;
    }

    .contact-button {
        height: 48px;
        border-radius: 2px;
        border: solid 1px #003fce;
        line-height: 46px;
        cursor: pointer;
    }

    .contact-button:hover {
        box-shadow: 0 10px 15px 0 rgba(31, 62, 134, 0.2);
        background-color: #112f61;
    }

    .contact-button a {
        font-family: TheMix-Plain;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #003fce;
        border: unset;
    }

    a:hover {
        text-decoration: none;
    }

    .contact-button:hover a {
        color: #ffffff;
    }

    @media (max-width: 991px) {

        .contact-two-persons div {
            text-align: center !important;
        }

        #finanzierung-förderprogramme .image-block-text button {
            width: 100%;
        }

        .col-12 .mt-2 p, .item-text>p, .vue-image-text-block-li ul li, .subtitle {
            font-size: 16px;
        }

        .vue-contacts .vue-page-title-wrapper{
            margin-top: 25px!important;
            margin-bottom: 15px!important;
        }

        .contact-two-persons .contact-mobile {
            margin-top: 60px !important;
        }

    }

    @media (max-width: 1200px) {
        .contact-mobile {
            margin-top: 30px;
        }

        .contactName {
            margin-top: 20px;
            text-align: center;
        }

        .contactPosition {
            text-align: center;
        }

        .contactTitle {
            margin-top: 30px;
            text-align: center;
        }

    }
</style>