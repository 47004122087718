export class UtilityService {

    constructor() {

    }

    static searchLocalStorageLink = 'Search';

    addSearchToLocalstorage(link) {
        window.localStorage.setItem(UtilityService.searchLocalStorageLink, link);

    }

    removeSearchFromLocalstorage() {
        window.localStorage.removeItem(UtilityService.searchLocalStorageLink);
    }

    getSearchFromLocalStorage() {
        return window.localStorage.getItem(UtilityService.searchLocalStorageLink);
    }

    isWindowMobileWidth() {
        return window.innerWidth < 1000;
    }

    static removeDisableContentBody() {
        const dEl = document.getElementById("contentDisabledElement");
        dEl.className = dEl.className.replace(/\bvue-side-nav-content-disabled\b/g, "");
        // document.getElementById("vue-header").style.zIndex = "1";
    }

    static addDisableContentBody() {
        // document.getElementById("vue-header").style.zIndex = "2";
        const dEl = document.getElementById("contentDisabledElement");
        const className = 'vue-side-nav-content-disabled';
        const arr = dEl.className.split(" ");
        if (arr.indexOf(className) == -1) {
            dEl.className += " " + className;
        }
    }


}