<template>
    <div class="vue-conditions-wrapper mt-5 vue-conditions-content" v-if="data">
        <div class="row justify-content-center container-fluid-max">
            <div class="col-12 col-xl-10">
                <div class="row text-center">
                    <div class="col-12 mt-5">
                        <PageTitleComponent :data="{mainTitle: data.title}">
                        </PageTitleComponent>
                    </div>
                    <div class="col-12 mt-5 table-wrapper">
                        <table>
                            <tr>
                                <th v-for="col in data.table.columns" :key="col" class="subText">
                                    {{col.name}}
                                </th>
                            </tr>
                            <tr v-for="item in data.table.rows" :key="item">
                                <td v-for="col in item.values" :key="col" class="subText">
                                    {{col}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-12 mt-5 mb-5">
                        <img :src="baseUrl + 'assets/images/Icon-Download.png'" style="margin-right: 30px;">
                        <a :href="data.downloadLink.url" download class="subText" style="color: #003fce; font-weight:600">
                            {{data.downloadLink.text}}
                        </a>
                        <hr />
                        <p class="text-left text-info">{{data.info}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageTitleComponent from "./PageTitleComponent";

    export default {
        name: "ConditionsComponent",
        components: {PageTitleComponent},
        props: ['data', 'baseUrl'],
        data: function () {
            return {}
        },
        methods: {}
    }
</script>

<style scoped>
    .vue-conditions-wrapper {
        padding: 30px 30px 30px 30px;
    }

    .vue-conditions-content .container-fluid-max {
        background-color: #f7f9ff;
    }

    table {
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        height: auto;
    }

    table tr:first-child th {
        padding-bottom: 30px;
    }

    table tr:nth-child(2) td {
        padding-top: 30px;
    }

    table tr:last-child td {
        padding-bottom: 30px;
    }

    table tr th {
        border-bottom: 1px solid #C2C5CB;
        text-align: center;
        color: #112f61;
    }

    p.text-info {
        color: #67696C !important;
        font-size: 14px;
        width: 60%;
        margin:auto
    }

    table:last-child {
        border-bottom: 1px solid #C2C5CB;
    }

    hr {
        width: 60%;
        height: 1px;
        border: none;
        background-color: #C2C5CB;
        margin-top:45px
    }

    td {
        text-align: center;
        color: #67696c;
        padding: 20px 0;
    }

    @media (max-width: 991px) {
        .vue-conditions-wrapper {
            padding: 10px 0px 10px 0px;
        }

        .vue-page-title-wrapper.col-12.container-fluid-max.p-0.p-md-auto {
            margin-top:0 !important;
            margin-bottom: 0 !important
        }

        .table-wrapper table {
            padding: unset;
        }

        table {
            width: 100%;
        }
    }
</style>