<template>
    <div class="vue-portfolio-wrapper">
        <div class="row justify-content-center container-fluid-max">
            <div v-if="header" class="col-12 col-sm-12 col-md-12 text-center">
                <PageTitleComponent :data="{mainTitle: header, subTitle: subtitle}">
                </PageTitleComponent>
            </div>

            <div class="col-12 col-sm-12 col-xl-10 p-5 text-center">
                <div class="row">
                    <div v-bind:class="imageContainerClass(image.columnWidth, image.paddingSpace)"
                         v-for="image in images" :key="image.url" class="mt-4">
                        <img v-if="image.url" :src="image.url" class="w-100">
                        <div v-if="image.imageTitle" class="title">
                            {{image.imageTitle}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageTitleComponent from "./PageTitleComponent";
    import {UtilityService} from "../../services/utility-service";

    export default {
        name: "PortfolioComponent",
        components: {PageTitleComponent},
        props: ['header', 'images', 'subtitle'],
        data: function () {
            return {
                utility: new UtilityService()
            }
        },
        computed: {},
        methods: {
            imageContainerClass: function (columnWidth, paddingSpace) {
                let column = `col-4 p-4 col-lg-${columnWidth}`
                let padding = 'p-' + paddingSpace;
                return [column, padding];
            }

        }
    }
</script>

<style scoped>
    #wer-wir-sind-verantwortung .vue-portfolio-wrapper .vue-page-title-wrapper {   
        margin-top: 0;
        margin-bottom: 25px;
    }
    
    #wer-wir-sind-verantwortung .vue-portfolio-wrapper .col-12.col-sm-12.col-xl-10 {
        max-width: 100%;
        flex: 0 0 100%;
    }
    
    #wer-wir-sind-verantwortung .vue-portfolio-wrapper img {
        max-width: 200px;
        height: 100px;
        object-fit: contain;
    }

    #wer-wir-sind-verantwortung .vue-portfolio-wrapper .mt-4.p-null {
        margin-top: 5rem !important;
        margin-bottom:2.5rem;
    }

    #wer-wir-sind-verantwortung .vue-portfolio-wrapper .mt-4.p-null:nth-child(10) {
        height: 140px;
        margin-bottom: 0 !important
    }

    #wer-wir-sind-verantwortung .vue-portfolio-wrapper .mt-4.p-null:nth-child(10) {
        margin-top: 120px !important;
        margin-bottom: 0 !important
    }

    .vue-portfolio-wrapper .title {
        color: #112F61;
        font-weight: 500;
        font-size: 18px
    }

    .vue-portfolio-wrapper .title:before {
        content: "";
        display: block;
        margin: 0 auto;
        width: 40px;
        padding-top: 35px;
        margin-bottom: 25px;
        border-bottom: 4px solid #112f61;
    }

    #wer-wir-sind-netzwerk-und-partner .vue-portfolio-wrapper .mt-4:first-child img {
        max-width: 240px;
    }

    #wer-wir-sind-netzwerk-und-partner .vue-portfolio-wrapper .mt-4:nth-child(2) img,
    #wer-wir-sind-netzwerk-und-partner .vue-portfolio-wrapper .mt-4:nth-child(3) img  {
        margin-top: -25px
    }

    #wer-wir-sind-netzwerk-und-partner .vue-portfolio-wrapper .mt-4:nth-child(8) img  {
        margin-right: -100px;
        margin-top: 20px;
    }

    #wer-wir-sind-netzwerk-und-partner .vue-portfolio-wrapper .mt-4:nth-child(10) img  {
        margin-left: -100px;
    }

    .vue-portfolio-wrapper {
        padding: 0px 30px 30px 30px;
        margin: -75px 0 75px 0
    }

    @media (max-width: 991px) {
        .vue-portfolio-wrapper img {
            width: 95%;
        }
    }
</style>