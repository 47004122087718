<template>
    <div class="vue-info-item-wrapper">
        <div>
            <img class="col-sm-12 col-md-12 col-lg-12 infoImage" :src="info.imageUrl">
            <div class="item-text" v-html="info.text"></div>
        </div>
        <div class="link-bottom">
            <a :href="info.navigation.url" class="link-primary">
                <img :src="baseUrl + 'assets/images/link-pfeil.svg'" class="Link-Pfeil">
                {{info.navigation.title}}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InfoItemComponent",
        props: {
            info: {
                type: Object
            },
            baseUrl: {
                type: String
            }
        },
        data: function () {
            return {}
        },
        computed: {},
        methods: {}
    }
</script>

<style scoped>
    .vue-info-item-wrapper {
        height: 100%;
    }

    .link-bottom {
        position: absolute;
        bottom: -40px;
        left: 15px;
    }

    .item-text {
        font-family: TheMix-Plain;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #001835;
    }

    .infoImage {
        width: 100%;
        height: 250px;
        object-fit: cover;
        padding: 0;
        padding-bottom: 30px;
    }
</style>