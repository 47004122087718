<template>
  <div class="row">
    <div class="col-12 vue-header-navigation-web-btn-block pr-0">
      <div class="row justify-content-end" v-if="headerBtns">
        <div class="col-auto">
          <BtnComponent :label="headerBtns.creditCheck.text" :secondary="true"
                        @btnClicked="clicked(headerBtns.creditCheck.url)">
          </BtnComponent>
        </div>
        <div class="col-auto dzb-login pr-0">
          <BtnComponent :label="headerBtns.dzbPortal.text" :primary="true"
                        @btnClicked="clicked(headerBtns.dzbPortal.url)"
                        v-bind:style="{ 'background-image': 'url('+ baseUrl+ '/assets/images/icon-schloss.svg)'}"
          ></BtnComponent>
        </div>
      </div>
    </div>
    <div class="col-12 vue-header-navigation-web-nav-block animate pr-0" id="header-navigation-block">
      <div class="row justify-content-end">
        <div class="col-auto text-center pr-0">
          <ul class="nav navbar-nav">
            <div v-for="(link, index) in links" @click="linkClicked(link, index)" :key="link.title">
              <li class="nav-item vue-header-navigation-web-link-nav-block animate" v-if="link.visible"
                  v-bind:class="{'vue-header-link-nav-block-active': activeLink.index == index }">
                <a style="cursor: pointer;" v-bind:class="{searchLink: link.title === 'Search'}">
                  <div v-if="link.title !== 'Search'">
                    {{ link.title }} <img class="ml-1" :src="baseUrl + 'assets/images/pfad/pfad.png'">
                  </div>
                  <template v-else>
                    <img :src="baseUrl + 'assets/images/icon-search.svg'">
                  </template>
                </a>
              </li>
            </div>
            <div class="nav-item">
              <LanguageSwitcherComponent :lang="lang" :base-url="baseUrl"></LanguageSwitcherComponent>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UtilityService} from '../../services/utility-service'
import BtnComponent from '../lib/BtnComponent'
import LanguageSwitcherComponent from './LanguageSwitcherComponent'

export default {
  name: "HeaderNavigationComponent",
  components: {BtnComponent, LanguageSwitcherComponent},
  props: ['links', 'lang', 'headerBtns', 'baseUrl'],
  data: function () {
    return {
      activeLinkIndex: Number,
      activeLink: {
        link: null,
        index: null,
        clicked: false
      },
      utility: new UtilityService()
    }
  },
  watch: {},
  computed: {},
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('searchWord');

    if (!myParam) {
      this.utility.removeSearchFromLocalstorage();
    } else if (this.utility.getSearchFromLocalStorage()) {
      let link = {title: UtilityService.searchLocalStorageLink};
      this.activeLink.clicked = false;
      this.activeLinkIndex = 5;
      this.activeLink.index = 5;
      this.linkClicked(link, 5)
    }

    // if (this.utility.getSearchFromLocalStorage()) {
    //   let link = {title: UtilityService.searchLocalStorageLink};
    //   this.activeLink.clicked = false;
    //   this.activeLinkIndex = 5;
    //   this.activeLink.index = 5;
    //   this.linkClicked(link, 5)
    // }
  },
  methods: {
    linkClicked(link, index) {
      if (this.activeLink.link == link) {
        this.$emit('linkClicked', link);
        this.activeLink.link = null;
        this.activeLink.index = null;
        this.activeLink.clicked = false;
        this.removeOverflowHideFromBody();
        return;
      } else {
        this.addHideOverflowToBody()
      }
      this.activeLinkIndex = index;
      this.activeLink.link = link;
      this.activeLink.index = index;
      this.activeLink.clicked = !this.activeLink.clicked;

      if (link.title !== 'Search') {
        this.hideSearchResults();
      }

      this.$emit('linkClicked', link);
    },
    clicked(url) {
      this.utility.removeSearchFromLocalstorage();
      // window.location.href = url;
      window.open(url);
    },
    addHideOverflowToBody() {
      const body = document.getElementsByTagName('body');
      if (body) {
        body[0].classList.add("hideBodyOverflow");
      }
    },
    removeOverflowHideFromBody() {
      const body = document.getElementsByTagName('body');
      if (body) {
        body[0].classList.remove("hideBodyOverflow");
      }
    },
    hideSearchResults() {
      // const sResultText = document.getElementById('searchResultText');
      // if(sResultText) {
      //     sResultText.style.display = 'none';
      // }
    }
  }
}
</script>

<style scoped>
.dzb-login .vue-btn-primary {
  padding-left: 35px;
  background-repeat: no-repeat;
  background-position: 30px center;
  text-transform: uppercase !important;
}

.vue-header-navigation-web-btn-block {
}

.vue-header-navigation-web-nav-block {
  padding-top: 20px;
}

.vue-header-navigation-web-link-nav-block {
  margin-right: 30px;
  font-family: TheMix-Plain;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.88;
  letter-spacing: normal;
  text-align: left;
  color: #003fce;
  border-bottom: 4px solid white;
  position: relative;
}

.vue-header-navigation-web-link-nav-block:after {
  content: ' ';
  display: block;
  width: 0%;
  height: 4px;
  position: absolute;
  bottom: -24px;
  left: 0;
  background-color: white;
  webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.vue-header-navigation-web-link-nav-block:hover:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -24px;
  left: 0;
  background-color: #003fce;
}

.vue-header.scrolled .vue-header-navigation-web-link-nav-block:hover:after,
.vue-header.scrolled .vue-header-navigation-web-link-nav-block:after {
  bottom: -4px;
  webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.vue-header-navigation-web-link-nav-block a div img {
  transform: rotate(0);
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.vue-header-link-nav-block-active a div img {
  transform: rotate(180deg);
}

.vue-header-link-nav-block-active:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -24px;
  left: 0;
  background-color: #003fce;
}

a > img {
  vertical-align: middle;
}

.searchLink img {
  padding: 0 30px 0 30px
}

.searchLink {
  position: relative;
  cursor: pointer;
}

.searchLink:before,
.searchLink:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 60%;
  width: 1px;
  background-color: #003fce;
}

.searchLink:after {
  left: auto;
  right: 0;
}
</style>