<template>
    <div class="vue-side-nav-wrapper mt-5" id="vue-side-nav-wrapper">
        <div class="vue-side-nav-sidenav-open-btn text-center animate" @click="openNav()" v-if="!isSidenavOpen">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.534" height="26.674" viewBox="0 0 28.534 26.674">
                <g id="Gruppe_3163" data-name="Gruppe 3163" transform="translate(-602.433 -274.611)">
                    <g id="Gruppe_3162" data-name="Gruppe 3162" transform="translate(602.933 275.111)">
                        <path id="Pfad_5991" data-name="Pfad 5991" d="M602.933,275.111V296.02l3.694-3.549h18.794v-17.36Z" transform="translate(-602.933 -275.111)" fill="none" stroke="#003fce" stroke-width="1"/>
                        <path id="Pfad_5992" data-name="Pfad 5992" d="M630.626,281.154v16.1l-2.844-2.732H613.315V281.154h17.312Z" transform="translate(-603.093 -272.249)" fill="#fff" stroke="#003fce" stroke-width="1"/>
                        <g id="Gruppe_3161" data-name="Gruppe 3161" transform="translate(14.267 13.125)">
                            <line id="Linie_1021" data-name="Linie 1021" x1="9.221" fill="none" stroke="#003fce" stroke-width="1"/>
                            <line id="Linie_1022" data-name="Linie 1022" x1="9.221" transform="translate(0 2.699)" fill="none" stroke="#003fce" stroke-width="1"/>
                            <line id="Linie_1023" data-name="Linie 1023" x1="9.221" transform="translate(0 5.398)" fill="none" stroke="#003fce" stroke-width="1"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>

        <div id="vue-side-nav-sidenav" class="vue-side-nav-sidenav animate">
            <div class="row">
                <div class="col-12 col-lg-12 text-right mt-3">
                    <a href="javascript:void(0)" class="vue-side-nav-sidenav-close-btn pr-5" @click="closeNav()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414" viewBox="0 0 22.414 22.414">
                            <g id="Gruppe_3350" data-name="Gruppe 3350" transform="translate(-1633.793 -30.793)">
                                <line id="Linie_1024" data-name="Linie 1024" x1="21" y2="21" transform="translate(1634.5 31.5)" fill="none" stroke="#003fce" stroke-width="2"/>
                                <line id="Linie_1025" data-name="Linie 1025" x2="21" y2="21" transform="translate(1634.5 31.5)" fill="none" stroke="#003fce" stroke-width="2"/>
                            </g>
                        </svg>
                    </a>
                </div>

                <div class="pt-5">
                    <div class="col-12 col-lg-12">
                        <PageTitleComponent style="margin-bottom: unset; margin-top: unset;" :data="pageTitle"></PageTitleComponent>
                    </div>

                    <div class="col-12 col-lg-12 mt-3">
                        <div class="row mt-3" v-for="(item, index) in navItems" :key="item.image">
                            <div class="col-5 col-sm-5 text-right">
                                <img :src="item.image">
                            </div>
                            <div class="col-7 col-lg-7 text-left subText" style="font-size: 24px;color: #112f61;">
                                {{item.text}}
                            </div>
                            <div class="col-12 col-lg-12 text-center mt-3 subText">
                                {{item.infoText}}
                            </div>
                            <div class="col-12 col-lg-12 text-center mt-4">
                                <button class="vue-side-nav-link" @click="navigateTo(item.link.url)">{{item.link.text}}</button>
                            </div>
                            <div class="col-12 col-lg-12 mt-3" v-if="index !== navItems.length -1">
                                <div class="row justify-content-center">
                                    <div class="col-10 col-lg-10 "><hr></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {UtilityService} from '../../services/utility-service'
    import PageTitleComponent from "../pages/PageTitleComponent";

    export default {
        name: "SideNavComponent",
        components: {PageTitleComponent},
        props: ['pageTitle','navItems', 'baseUrl'],
        data: function () {
            return {
                isSidenavOpen: false,
                body: null,
                utility: new UtilityService()
            }
        },
        methods: {
            openNav: function () {
                this.clickOnSidenav();
                document.getElementById("vue-side-nav-sidenav").classList.add('open');
            },

            closeNav: function () {
                this.clickOnSidenav();
                document.getElementById("vue-side-nav-sidenav").classList.remove('open');
            },

            clickOnSidenav: function () {
                this.isSidenavOpen = !this.isSidenavOpen;
                this.body = document.body;
                this.body.classList.toggle("vue-side-nav-body");
                document.getElementById("contentDisabledElement").classList.toggle("vue-side-nav-content-disabled");
            },

            navigateTo: function (url) {
                window.location.href = url;
            },

            removeBtnIfMobileScreen: function () {
                if (this.utility.isWindowMobileWidth()) {
                    document.getElementById("vue-side-nav-wrapper").style.display = 'none';
                } else {
                    document.getElementById("vue-side-nav-wrapper").style.display = 'unset';
                }
            }
        },

        mounted: function () {
            if (this.utility.isWindowMobileWidth()) {
                document.getElementById("vue-side-nav-wrapper").style.display = 'none';
            } else {
                document.getElementById("vue-side-nav-wrapper").style.display = 'unset';
            }
        },

        created() {
            window.addEventListener('resize', this.removeBtnIfMobileScreen)
        }
        ,

        beforeDestroy() {
            window.removeEventListener('resize', this.removeBtnIfMobileScreen)
        }
    }
</script>

<style scoped>
    .vue-side-nav-sidenav.open {
        right: 0;
    }

    .vue-side-nav-wrapper {

    }

    .vue-side-nav-sidenav {
        height: 100%;
        width: 35%;
        position: fixed;
        z-index: 2;
        top: 0;
        right: -35%;
        background-color: white;
        overflow-x: hidden;
    }

    .vue-side-nav-sidenav-open-btn {
        width: 60px;
        height: 60px;
        position: fixed;
        z-index: 1;
        cursor: pointer;
        top: 40%;
        right: 0;
        -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
        background-color: #ffffff;
    }

    .vue-side-nav-sidenav-open-btn:hover {
        background-color: #003fce;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    .vue-side-nav-sidenav-open-btn:hover svg path,
    .vue-side-nav-sidenav-open-btn:hover svg g line {
        stroke: #ffffff;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    .vue-side-nav-sidenav-open-btn svg #Pfad_5992 {
        fill: #white;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    .vue-side-nav-sidenav-open-btn:hover svg #Pfad_5992 {
        fill: #003fce;
    }

    .vue-side-nav-sidenav-open-btn svg {
        margin:auto;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    .vue-side-nav-sidenav-close-btn {
        right: 50px;
        font-size: 46px;
    }

    .vue-side-nav-sidenav-open-btn-img {
        position: relative;
        top: 25%;
    }

    .vue-side-nav-body {
        overflow: hidden;
    }

    .vue-side-nav-content-disabled {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background: rgba(17, 47, 97, 0.75);
        z-index: 2 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vue-side-nav-link {
        width: 200px;
        height: 48px;
        border-radius: 2px;
        border: solid 1px #003fce;
        font-family: TheMix-Plain;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #003fce;
        text-transform: uppercase;
        background: white;
    }
</style>