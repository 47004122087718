<template>
    <div class="vue-credit-check-steps-wrapper mb-5 container-fluid-max" v-if="data">
        <div class="row justify-content-center">
            <div class="col-lg-1"></div>
            <div class="col-12 col-sm-12 col-lg-10">
                <div class="row">
                    <div class="col-12 col-md-4 mt-2" v-for="(item, index) in data.items" :key="item.title">
                        <div class="row text-center">
                            <div class="col-12">
                                <div class="stepIndex">{{index + 1}}.</div>
                            </div>
                            <div class="col-12 mt-4">
                                <img :src="item.title.image" class="icons-steps">
                                <p class="stepsText">{{item.title.text}}</p>
                            </div>
                            <div class="col-12 mt-4 subText">
                                {{item.textBlock}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>

        <div class="row justify-content-center mt-5">
            <div class="col-sm-4 text-center">
                <BtnComponent style="width: 100%;" :label="data.btnStartCheck.label" :primary="true"
                              @btnClicked="navigateToFinanz(data.btnStartCheck.url)"></BtnComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import BtnComponent from "../lib/BtnComponent";

    export default {
        name: "CreditCheckStepsComponent",
        components: {BtnComponent},
        props: ['data'],
        data: function () {
            return {}
        },
        methods: {
            navigateToFinanz(url) {
                window.location.href = url;
            }
        }
    }
</script>

<style scoped>
    .vue-btn-primary {
        max-width: 300px;
    }

    .vue-credit-check-steps-wrapper {
        padding-top: 0px;
        padding-bottom: 30px
    }

    .stepIndex {
        font-family: TheMix-Plain;
        font-size: 80px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.6;
        letter-spacing: normal;
        text-align: center;
        color: #112f61;
        padding-bottom: 40px;
    }

    a {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #112f61;
    }

    p.stepsText {
        width: 100%;
        text-align: center;
        font-weight: 500
    }

    img.icons-steps {
        width: 25%;
        position: absolute;
        left:0;
        top:-30px
    }

    .col-12.col-md-4.mt-2:nth-child(2) img.icons-steps {
        left:30px
    }

    .col-12.col-md-4.mt-2:first-child img.icons-steps {
        left:-30px
    }

    @media (max-width: 991px) {
        .vue-credit-check-steps-wrapper {
            padding: unset;
        }
    }
</style>