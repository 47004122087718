<template>
    <div class="dropdown" v-if="lang">
        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="languageLink" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" ref="languageLink">
            DE
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" :href="lang.de">DE</a>
            <a class="dropdown-item" :href="lang.en">EN</a>
            <a class="dropdown-item" :href="lang.fr">FR</a>
            <a class="dropdown-item" :href="lang.sp">ES</a>
          <!--<a class="dropdown-item" :href="lang.nl">NL</a>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "LanguageSwitcherComponent",
        props: ['lang', 'baseUrl'],
        data: function () {
            return {}
        },
        methods: {
            checkLanguage() {
                const language = window.location.pathname.substring(1, 3);
                let isValid = false;
                switch (language.toLowerCase()) {
                    case 'en':
                        isValid = true;
                        break;
                    case 'es':
                        isValid = true;
                        break;
                    case 'fr':
                        isValid = true;
                        break;
                    case 'nl':
                        isValid = true;
                        break;
                    default:
                        isValid = false;
                        break;
                }

                const languageLink = document.getElementById('languageLink');
                languageLink.style.backgroundImage = 'url('+ this.baseUrl +'/assets/images/pfad/pfad.png)'

                if (isValid) {
                    languageLink.innerText = language.toUpperCase();
                } else {
                    languageLink.innerText = 'DE';
                }
            }
        },
        updated() {
            this.checkLanguage();
        }
    }
</script>

<style scoped>
    #languageLink {
        background-color: white !important;
        border: none;
        padding: 0px;
        font-family: TheMix-Plain;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.88;
        letter-spacing: normal;
        text-align: left;
        color: #003fce;
        background-position: right center;
        background-repeat: no-repeat;
        padding-right: 30px;
    }

    #languageLink:hover, #languageLink:focus, #languageLink:active {
        outline: none !important;
        border:0
    }

    .dropdown-toggle::after {
        display: none;
    }
</style>