<template>
    <div class="vue-erfahrungs-berichte-wrapper container-fluid-max" v-if="data">
        <div class="row">
            <div class="col-12 col-sm-12 text-center">
                <PageTitleComponent :data="data.header" class="mt-0 mb-0">
                </PageTitleComponent>
            </div>

            <template v-if="data.reports.length == 1">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-10 mt-5 pt-5">
                            <div class="row align-items-center">
                                <div class="col-sm-12 col-md-6 text-center">
                                    <img class="itemImage" :src="data.reports[0].imageUrl">
                                </div>
                                <div class="col-12 col-sm-12 col-md-6">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 mt-3" v-html="data.reports[0].text"></div>
                                        <div class="col-12 col-sm-12">
                                            <a :href="data.reports[0].navigation.url" class="animate link-primary">
                                                <img :src="baseUrl + 'assets/images/link-pfeil.svg'" class="Link-Pfeil">
                                                {{data.reports[0].navigation.title}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </template>

            <template v-else>
                <div class="col-12 col-sm-12 col-md-4 text-center mt-5" v-for="report in data.reports" :key="report">
                    <InfoItemComponent :info="report" :base-url="baseUrl"></InfoItemComponent>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import PageTitleComponent from "./PageTitleComponent";
    import InfoItemComponent from "./InfoItemComponent";

    export default {
        name: "EfahrungsberichteComponent",
        components: {InfoItemComponent, PageTitleComponent},
        props: ['data', 'baseUrl']
    }
</script>

<style scoped>
    .vue-erfahrungs-berichte-wrapper {
        padding-bottom: 100px;
        margin-top: 80px !important
    }

    .vue-erfahrungs-berichte-wrapper .main-title-big-col8 {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-bottom: 25px
    } 

    .vue-erfahrungs-berichte-wrapper .itemImage {
        width: 95%;
    }

    @media (max-width: 991px) {
        .vue-erfahrungs-berichte-wrapper {
            text-align: center;
            padding: 10px 0px 10px 0px;
        }

        .vue-erfahrungs-berichte-wrapper .itemImage {
            width: 95%;
        }
    }
</style>