<template>
  <div class="vue-header-menu-web">
    <div>
      <div class="container-fluid-max">
        <div class="row justify-content-center">
          <div class="col-8" v-if="link.title === searchLocalStorageLink">
            <div class="row">
              <form class="col-sm-12 col-md-12 col-lg-12">
                <div class="form-group search">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.28" height="15.28"
                       viewBox="0 0 15.28 15.28">
                    <g id="Icon-Suche" transform="translate(0.75 0.75)">
                      <g id="Gruppe_1514" data-name="Gruppe 1514"
                         transform="translate(-389 -345)">
                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="5.923" cy="5.923"
                                r="5.923" transform="translate(389 345)" fill="none"
                                stroke="#112f61" stroke-miterlimit="10" stroke-width="1.5"/>
                        <line id="Linie_12" data-name="Linie 12" x2="4" y2="4"
                              transform="translate(399 355)" fill="none" stroke="#112f61"
                              stroke-miterlimit="10" stroke-width="1.5"/>
                      </g>
                    </g>
                  </svg>
                  <label for="link-search" class="searchText bold" v-if="search">{{ search.label }}</label>
                  <div class="input-group mt-3">
                    <input id="link-search" v-if="search" name="searchWord" type="text"
                           class="form-control searchText"
                           :placeholder="search.placeholder" v-model="searchWord"
                           v-on:focusout="onFocusOut()">
                    <div class="input-group-append">
                      <button value="Go" class="btn btn-outline-secondary pl-3" type="submit"
                              id="button-addon2" @click="searchResult()">
                        <img :src="baseUrl + 'assets/images/link-pfeil-links-suche.svg'">
                      </button>
                    </div>
                  </div>
                  <div :key="componentKey" class="mt-4" v-if="sResult" id="searchResultText">
                    <p class="searchText bold" v-if="search.resultHeading">{{ search.resultHeading }}</p>
                    <p class="mt-3 mb-0 searchText" style="font-size: 28px;" v-if="search.result">
                      {{ sResult.searchLength }} {{ search.result }}
                      "{{ utility.getSearchFromLocalStorage() }}"
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <template v-else>
            <div class="vue-header-menu-web-border" v-for="en in link.entries" :key="en.title"
                 v-bind:class="{'col-5': link.entries.length <= 2 , 'col-4': link.entries.length > 2 && en.visible}">
              <div class="card vue-header-menu-web-card" v-if="en.visible">
                <div class="card-body">
                  <div class="row">
                    <div class="subnav-image">
                      <img :src="en.image">
                    </div>
                    <div class="col-12 padding-left">
                      <h5 class="card-title">{{ en.heading }}</h5>
                      <div class="vue-header-menu-second-title-list">
                        <h6 class="card-subtitle mb-2 text-muted" :if="en.secondHeading">
                          {{ en.secondHeading }}
                        </h6>
                        <ul class="vue-header-menu-web-card-ul">
                          <div v-for="(l) in en.linkEntries" :key="l.title">
                            <li v-if="l.visible">
                              <a :href="l.url" class="link-primary"
                                 @click="linkClicked()">
                                <img :src="baseUrl + 'assets/images/link-pfeil.svg'" class="Link-Pfeil">
                                {{ l.title }}
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="container-fluid-max searchresults" v-if="link.title === searchLocalStorageLink">
        <div class="row justify-content-center">
          <div class="col-8" v-if="sResult">
            <ul class="list-group list-group-flush list-group-ul">
              <li class="list-group-item mb-3" v-for="item in sResult.items" :key="item">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.914" height="11.414" viewBox="0 0 21.914 11.414">
                  <g id="Link-Pfeil" transform="translate(0 0.707)">
                    <g id="Gruppe_1515" data-name="Gruppe 1515" transform="translate(-276 -281.5)">
                      <line id="Line_4" data-name="Line 4" x1="20" transform="translate(276 286.5)" fill="none"
                            stroke="#112f61" stroke-width="2"/>
                      <path id="Pfad_2646" data-name="Pfad 2646" d="M291.5,281.5l5,5-5,5" fill="none" stroke="#112f61"
                            stroke-width="2"/>
                    </g>
                  </g>
                </svg>
                <a :href="item.url" @click="linkClicked()" style="padding-left: 20px;" class="searchText">
                  {{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UtilityService} from "../../services/utility-service";

export default {
  name: "HeaderMenuWebComponent",
  props: {
    link: {
      type: Object
    },
    search: {
      type: Object
    },
    sResult: {
      type: Object
    },
    baseUrl: {}
  },
  data: function () {
    return {
      searchResultList: [],
      searchWord: '',
      searchLocalStorageLink: UtilityService.searchLocalStorageLink,
      componentKey: 0,
      utility: new UtilityService()
    }
  },
  computed: {},
  watch: {
    searchResultList: function (val) {
      if (val.length > 0) {
        this.forceRerender();
      }
    }
  },
  mounted() {
    this.searchWord = this.utility.getSearchFromLocalStorage();
  },
  methods: {
    onFocusOut() {
      // this.searchWord = '';
    },
    searchResult: function () {
      this.utility.addSearchToLocalstorage(this.searchWord, true);
    },
    linkClicked: function () {
      this.utility.removeSearchFromLocalstorage();
    },
    forceRerender() {
      this.componentKey += 1;
    }
  }
}
</script>

<style scoped>
.card-body .col-12.padding-left {
  padding-left: 100px;
}

.vue-header.scrolled .vue-header-menu-web {
  margin-top: 1px
}

.vue-header-menu-web {
  position: absolute;
  width: 100%;
  background: white;
  margin-top: 21px;
  webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.subnav-image {
  position: absolute;
  left: 50px;
}

.subnav-image img {
  max-width: 42px;
  width: 100%;
}

.vue-header-menu-web-card-wrapper {
  padding-top: 50px;
  padding-bottom: 20px;
}

.vue-header-menu-web-card {
  border: 0;
  width: auto;
  display: flex;
  height: 100%;
}

.vue-header-menu-web-card > .card-body > .row {
  border-left: 1px dashed grey;
  height: 100%
}

.vue-header-menu-web-card-ul li {
  list-style: none;
  padding-left: 0
}

.vue-header-menu-web-card-ul {
  text-align: left;
  padding-left: 0;
}

.vue-header-menu-web-card > .card-body {
  padding: 50px 0;
}

h5 {
  font-family: TheMix-Plain;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

h6 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #67696c;
}

.col-auto {
  padding: unset;
}

.col-2 {
  padding: unset;
}

.vue-header-menu-second-title-list h6 {
  padding-top: 23px;
}

.vue-header-menu-web-border {
  border: unset;
  border-radius: unset;
}

/* width */
.container-fluid-max.searchresults .row .col-8::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.container-fluid-max.searchresults .row .col-8::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.container-fluid-max.searchresults .row .col-8::-webkit-scrollbar-thumb {
  background: #003fce;
}

/* Handle on hover */
.container-fluid-max.searchresults .row .col-8::-webkit-scrollbar-thumb:hover {
  background: #112F61
}

.container-fluid-max.searchresults .row,
.container-fluid-max.searchresults .row .col-8 {
  height: 100%
}

.container-fluid-max.searchresults .row .col-8 {
  overflow: auto;
}

.container-fluid-max.searchresults {
  position: absolute;
  height: calc(100vh - 500px);
  left: 0;
  right: 0;
  width: 100%;
  padding: 50px 0;
}

.scrolled .container-fluid-max.searchresults {
  height: calc(100vh - 470px);
}

.list-group li.list-group-item a {
  padding-left: 80px !important;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding-top: 30px;
  cursor: pointer;
  font-weight: 600
}

.list-group li.list-group-item a:hover {
  text-decoration: none;
}

.list-group li.list-group-item:hover a,
.list-group li.list-group-item:hover {
  background-color: #003fce;
  color: white;
}

.list-group li.list-group-item:hover svg,
.list-group li.list-group-item:hover svg line,
.list-group li.list-group-item:hover svg path {
  stroke: white;
  z-index: 2;
  position: relative;
}

.list-group-ul {
  width: 100%;
  padding-left: 15px;
}

.card-title {
  margin-bottom: unset;
}

.searchText.bold {
  font-weight: 600;
}

.searchText {
  font-family: TheMix-Plain;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #112f61;
}

.form-group.search {
  padding-top: 50px;
  padding-bottom: 36px;
}

.form-group.search svg {
  margin-right: 15px;
  margin-top: -5px;
}

#link-search::placeholder {
  font-size: 58px;
  color: #E7E8EA;
  font-weight: normal;
}

#link-search {
  padding: 0 50px 0 0;
  border: 0;
  border-bottom: 1px solid rgba(17, 47, 97, 0.75);
}

input, button {
  height: 95px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(17, 47, 97, 0.75);
}

.form-control:focus {
  box-shadow: 0;
  border-color: 0;
  outline: none;
}

.form-control:active {
  box-shadow: 0;
  border-color: 0;
  outline: none;
}

button {
  /*width: 30px;*/
  padding: unset;
}

button:hover {
  background-color: none;
}

button:active {
  background-color: none;
}

.btn-outline-secondary:hover {
  background-color: white;
}

.btn-outline-secondary:focus {
  box-shadow: 0;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: none;
  box-shadow: 0;
}

.btn.focus, .btn:focus {
  box-shadow: 0;
}

.list-group-item {
  padding: 30px;
}

.webMenuLink {
  font-family: TheMix-Plain;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #003fce;
}
</style>